import { Flex, HStack, Text } from "@chakra-ui/layout";
import { Link } from "@chakra-ui/react";
import { logAdminClick } from "../../utils/analytics/Plausible";
import { useTranslation } from "../../utils/hooks/useTranslation";
import InternalLink from "../InternalLink";
import { useSanityAuth } from "../sanityAuth/useSanityAuth";
import { usePreviewContext } from "./previewContext";
import { somePreviewID } from "./StudioSOMEPreview";

function PreviewBanner() {
  const { t } = useTranslation();
  const context = usePreviewContext();
  const authStatus = useSanityAuth();

  if (!context.isOn || authStatus.loading) {
    return null;
  }

  return (
    <Flex
      justifyContent="center"
      backgroundColor="hsl(0, 100%, 38%, 0.6)"
      color="white"
      fontSize="0.8rem"
      padding="0.25rem"
      fontWeight="600"
      position="sticky"
      top="0"
      zIndex="1000"
    >
      {authStatus.isAuthenticated ? (
        <HStack>
          <InternalLink variant="plain" _hover={{ textDecoration: "underline" }} href={`#__next`}>
            {t("Forhåndsvisning")}
          </InternalLink>
          <InternalLink
            _hover={{ textDecoration: "underline" }}
            href={`#${somePreviewID}`}
            onClick={() => logAdminClick("SOME-preview-link")}
          >
            {t("Sosiale medier")}
          </InternalLink>
        </HStack>
      ) : (
        <Text>
          {t("Du må")} <Link href="/cms/">{t("logge inn")}</Link> {t("for forhåndsvisning")}
        </Text>
      )}
    </Flex>
  );
}

export default PreviewBanner;
