import { DecorativeImage, List, Modify, Publication, Site } from "@libry-content/types";
import { getSiteUrl } from "./currentSite";

export type PublicationWithImage = Modify<Publication, { image: DecorativeImage }>;

export const isPublicationWithImage = (publication?: Publication): publication is PublicationWithImage =>
  !!publication?.image?.asset;

export const getFirstThreePublicationsWithImage = (publications: Publication[] = []): PublicationWithImage[] =>
  (publications?.filter(isPublicationWithImage) || []).slice(0, 3);

export const getBookFanOGImageUrl = (list: List, site: Site) => {
  const imageAssetIds = getFirstThreePublicationsWithImage(list.publications).map(
    (publication) => publication.image.asset._ref
  );
  return `${getSiteUrl(site, {
    skipLanguage: true,
  })}/api/og-images/bookfan/${imageAssetIds.join(",")}`;
};
