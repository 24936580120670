import { Site } from "@libry-content/types";
import { getSiteUrl } from "./currentSite";

export const getBookCoverOGImageUrl = (site: Site, imageSrc?: string) => {
  const domain = getSiteUrl(site, {
    skipLanguage: true,
  });

  return `${domain}/api/og-images/book-cover/${encodeURIComponent(imageSrc ?? "")}`; // Fallback to empty string (""). The endpoint will then return a fallback-image to use for books where we don't have a cover-image
};
